import React, { useEffect, useState } from "react";
import Back from '../Images/Back-512.png';
import '../Styles/TopBar.css';
interface ITopBarProps {
    goBack: () => void;
    goFrontPage: () => void;
}

export const TopBar: React.FC<ITopBarProps> = ({ goBack, goFrontPage }) => {
    const [topbarHeight, settopbarHeight] = useState('8em');
    const [topBarTextMarginTop, settopBarTextMarginTop] = useState('2.7em');
    const [topBarPicMargin, setTopBarPicMargin] = useState('5.6em');
    const [prevHeight, setPrevHeight] = useState(0);
    useEffect(() => {
        window.addEventListener('scroll', e => handleNavigation(e));
        return () => { window.removeEventListener('scroll', handleNavigation) };
    }, [prevHeight]);

    const handleNavigation = (e) => {
        const window = e.currentTarget;
        const skipCheck = window.scrollY - prevHeight;
        if (skipCheck < 100 && skipCheck > -100)
            return;
        if (window.innerHeight - window.scrollY >= window.innerHeight - 50) {
            if (topbarHeight !== '8em') {
                settopbarHeight('8em');
                settopBarTextMarginTop('2.7em')
                setTopBarPicMargin('5.6em');

            }
        } else {
            if (topbarHeight !== '2.5em') {
                settopbarHeight('2.5em');
                settopBarTextMarginTop('0em')
                setTopBarPicMargin('0.3em');

            }
        }
        setPrevHeight(window.scrollY);

    }

    return (
        <div className="fb-top-bar" style={{ height: topbarHeight }}>
            <div className="row" style={{ height: topbarHeight, zIndex:9 }}>
                <div className="col-2" style={{height:'inherit'}}>
                    <img onClick={e => goBack()} src={Back} style={{marginTop:topBarPicMargin}} />
                </div>
                <div style={{zIndex:0, position:'relative',height:'inherit'}} className="col-10">
                    <h2 style={{marginTop:topBarTextMarginTop, display:'block'}} onClick={e => goFrontPage()}>Photobook</h2>
                </div>
            </div>


        </div>);
};