import React, {  } from "react";
import { Home } from './Views/Layout';
import { BrowserRouter as Router } from 'react-router-dom';

interface IAppProps { }

export const App: React.FC<IAppProps> = () => {


  return (
    <Router>
<Home />
    </Router>

  );
};