import React, { useEffect }  from "react";

interface ISpinnerProps {

}

export const Spinner: React.FC<ISpinnerProps> = ({}) => {
    return (
        <div className="d-flex justify-content-center">
              <div  style={{color:'#767af7'}} className="spinner-border" role="status" />
            <p>Loading...</p>
        </div>

    );
};