import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Fotoalbum } from "./Fotoalbum";
import { ApiPrefix } from "../Misc/Api";
import '../Styles/LandingPage.css';
interface ILandingPageProps {

}

export const LandingPage: React.FC<ILandingPageProps> = ({ }) => {
    const [searchWord, setsearchWord] = useState('');
    const history = useHistory();
    const [brugere, setBrugere] = useState<IBruger[]>([])
    useEffect(() => {
        const request = async () =>{
            const result = await fetch(ApiPrefix + 'api/bruger/');
            const json = await result.json();
            setBrugere(json);
        }
        request();
    },[])
    
    const handleSearch = (userId: string) => {
            history.push(`/search/${userId}`)
    }
    
    return (
        <div>
      <div className="row">
        {brugere.map((x) => (
          <div key={x.id} className="col-6">
            <div onClick={(e) => handleSearch(x.sharedId)} className="menu-box-item text-center">
              <h2>{x.navn}</h2>
            </div>
          </div>
        ))}
      </div>
        </div>
    );
};

export interface IBruger {
    fotoAlbum?:Fotoalbum[];
    id:number;
    sharedId:string;
    navn:string;
}