import React, { useEffect, useMemo, useState } from "react";
import { match } from "react-router";
import { IFoto } from "../Components/Foto";
import {Spinner} from '../Components/Spinner';
import {ApiPrefix} from '../Misc/Api';
import {FotoAlbumContainer} from '../Components/FotoAlbumContainer';
import { useHistory } from "react-router-dom";
interface IFotoAlbumProps { 
    match:match<{id:string}>;
}

export const FotoAlbum: React.FC<IFotoAlbumProps> = ({match}) => {
    const [fotoAlbum, setFotoAlbum] = useState<Fotoalbum[]>([]);
    const [loading,setLoading] = useState(true);
    const history = useHistory();

    const handleSelect = (id:number) => {
            history.push(`/albumdetails/${match.params.id}/${id}`)
    }
    useEffect(() => {
        const fetchData = async() => {
            const request:RequestInit = {
                method:'POST',
                body:JSON.stringify(match.params.id),
                headers:{'Content-Type':'application/json'},

            }
            const result = await fetch(ApiPrefix + 'api/bruger/', request);
            if(result.status === 200){
                const json = await result.json();
                const fotoalbums:Fotoalbum[] = json.fotoAlbum;
                console.log(fotoalbums);

                const aarsBilleder = fotoalbums.find(x=> x.id === 9);
                if(aarsBilleder !== undefined){
                    const sortedArray = [aarsBilleder, ...fotoalbums.filter(x=> x.id !== 9)] 
                    setFotoAlbum(sortedArray??[]);
                }
                else{
                    console.log("her");
                    setFotoAlbum(fotoalbums);
                }
                console.log(fotoAlbum);
            }
            setLoading(false);
            
        }
        fetchData();
    }, [match]);
const getPreviewFotos = (id:number) => {
    const album = fotoAlbum.find(x=> x.id === id);
if(album){
    if(album.fotos){
        const fotoalbum = [...album.fotos];

        if(fotoalbum.length>3){
            const startIndex = Math.floor(Math.random() * (fotoalbum.length-3))
            return fotoalbum.slice(startIndex,startIndex+3);
        }
        else{
            return fotoalbum.slice(0,album.fotos.length)
        }
    }
   
}

    return [];
}
    return (
        loading?<Spinner />:
        fotoAlbum.length>0?<React.Fragment>
            <div className="row">
            {fotoAlbum.map((x,y)=> <div key={x.id} className="col-xl-4 col-10 offset-1 offset-lg-0"><FotoAlbumContainer fotoPreviews={getPreviewFotos(x.id)} id={x.id} handleClick={handleSelect}  beskrivelse={x.beskrivelse} overskrift={x.navn} antalBillederIAlbum={x.fotos?.length}/></div>  )}
            </div>
        </React.Fragment>:<h2>Ingen album</h2>

        
        
    );
};

export interface Fotoalbum {
    id:number;
    navn:string;
    beskrivelse:string;
    oprettetDato?:Date;
    fotos:IFoto[];
}
