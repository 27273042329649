import React from "react";
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Col, Row, Container } from 'react-bootstrap';
import { LandingPage } from './LandingPage';
import '../Styles/HomeStyle.css';
import { FotoAlbum } from "./Fotoalbum";
import {FotoalbumDetails} from "./FotoalbumDetails";
import { TopBar } from '../Components/TopBar';
interface IHomeProps {

}

export const Home: React.FC<IHomeProps> = ({ }) => {
    const hist = useHistory();

    return (
        <div className="fb-wrap-all-site">
            <TopBar goFrontPage={ ()=> hist.push('/')} goBack={()=>hist.goBack()}/>
            <div style={{height:'6em'}} />
            <div className="fb-front-page-container">
                <Container>
                    <Row>
                        <Col xl={{span:12, offset:0}} sm={{ offset: 1, span: 10 }}>
                            <Switch>
                                <Route exact path="/" component={LandingPage} />
                                <Route path="/search/:id" component={FotoAlbum} />
                                <Route path="/albumdetails/:sharedid/:albumid" component={FotoalbumDetails} />
                                <Redirect to="/" />
                            </Switch>
                        </Col>
                    </Row>
                </Container>


            </div>
        </div>
    );
};


