import React, { Fragment } from "react";
import "../Styles/FotoStyle.css";
interface IFotoProps {
    foto: IFoto;
    setModalFoto: (id: number) => void;
}

export const Foto: React.FC<IFotoProps> = ({ foto, setModalFoto }) => {
    return (
        <Fragment>
            <div
                onClick={(e) => setModalFoto(foto.id)}
                className="foto-wrapper">
                <div className="foto-container">
                    <img loading="lazy" alt="new" src={foto.url} />
                </div>
                <div className="foto-footer">
                    <p>{foto.beskrivelse}</p>
                </div>
            </div>
        </Fragment>
    );
};
export interface IFoto {
    url?: string;
    beskrivelse?: string;
    oprettetDato?: Date;
    pB_FotoalbumId?: number;
    id: number;
}
