import React, { Fragment, useEffect }  from "react";
import '../Styles/FotoAlbumContainer.css';
import { IFoto } from "./Foto";
interface IFotoAlbumContainerProps {
overskrift:string;
beskrivelse:string;
antalBillederIAlbum:number;
id:number;
handleClick:(id:number)=>void;
fotoPreviews:IFoto[];
}

export const FotoAlbumContainer: React.FC<IFotoAlbumContainerProps> = ({beskrivelse, overskrift, antalBillederIAlbum,id, handleClick,fotoPreviews}) => {
    useEffect(() => {
        
    }, []);
    return (
        <Fragment>

        <div className="foto-album-container-wrapper">
            <div className="foto-album-container-header">
                {overskrift}
            </div>
            <div className="foto-album-container-content">
            <p>{beskrivelse}</p>
            <div className="foto-album-contaer-preview-fotos">
                {fotoPreviews.map((x,y) => 
                <img src={x.url} key={x.id} />
                )}
            </div>
            <h4>{antalBillederIAlbum} Billeder i album </h4>
            </div>
            <div className="foto-album-container-footer">
            <button onClick={e=>handleClick(id)}>Se Album</button>
            </div>
            </div>
            </Fragment>
    );
};