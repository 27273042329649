import React, { Fragment, useEffect, useState }  from "react";
import Modal from "react-modal";
import {match} from 'react-router';
import { Foto, IFoto } from "../Components/Foto";
import {ApiPrefix} from '../Misc/Api';
import { Fotoalbum } from "./Fotoalbum";


interface IFotoalbumDetailsProps {
match:match<{sharedid:string, albumid:string}>
}

export const FotoalbumDetails: React.FC<IFotoalbumDetailsProps> = ({match}) => {
    const[album,setAlbum] = useState<Fotoalbum>();
    const [currentlyViewing, setcurrentlyViewing] = useState(0);
    const[displayFotos, setDisplayFotos] = useState<IFoto[]>([]);
    const [modalOpen, setmodalOpen] = useState(false);
    const [modalDisplay, setmodalDisplay] = useState<IFoto>();

    useEffect(() => {
        async function fetchData(){
            const url= ApiPrefix+'api/fotoalbum?sharedId='+match.params.sharedid+'&albumId='+match.params.albumid;
           const response =  await fetch(url);
           if(response.status === 200){
            const album:Fotoalbum = await response.json();
            setAlbum(album);
           }
        }
        fetchData();
    }, []);

    useEffect(() => {
        map10images();
    }, [album]);

    useEffect(() => {
        window.addEventListener('scroll',handleScroll);
        return() => {window.removeEventListener('scroll',handleScroll);}
    }, [currentlyViewing]);
    
    const closeModal = () => {
        setmodalOpen(false);
    }
    const setModalFoto = (id:number) => {
        const cpAlbum = [...album!.fotos]
        const dpImg = cpAlbum.find(x=> x.id === id);
        setmodalDisplay(dpImg);
        setmodalOpen(true);
    }
    const { map10images, handleScroll } = imageDisplaying(album, currentlyViewing, displayFotos, setDisplayFotos, setcurrentlyViewing);

    return (
        <Fragment>
            <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          ariaHideApp={false}
        >
            <div style={{zIndex:99999, overflow:'hidden', marginTop:'7em'}}>
            <img src={modalDisplay?.url} style={{maxHeight:'100%', maxWidth:'100%'}} />
            <p>{new Date(modalDisplay?.oprettetDato!).toLocaleDateString()}</p>
            <p>{modalDisplay?.beskrivelse}</p>
            </div>

        </Modal>

        <Fragment>
 
            <div className="row">
            {album?
            displayFotos?.map((x,y) => 
            <div key={y} className="col-12 col-xl-6">
            <Foto setModalFoto={setModalFoto} foto={x}/></div> 
            )
        :null}
               </div>

        </Fragment></Fragment>
    );
};

function imageDisplaying(album: Fotoalbum | undefined, currentlyViewing: number, displayFotos: IFoto[], setDisplayFotos: React.Dispatch<React.SetStateAction<IFoto[]>>, setcurrentlyViewing: React.Dispatch<React.SetStateAction<number>>) {
    const map10images = () => {
        if (album !== undefined) {
            const albumLength = album!.fotos.length;
            if (currentlyViewing >= albumLength) {
                return;
            }
            let endIndex = currentlyViewing + 10;
            let startIndex = currentlyViewing;
            if (endIndex > albumLength) {
                endIndex = albumLength;
            }
            let fotoArr: IFoto[] = [];
            for (startIndex; startIndex < endIndex; startIndex++) {
                fotoArr.push(album.fotos[startIndex]);
            }
            let newArray = [...displayFotos];
            fotoArr.forEach(x => newArray.push(x));
            setDisplayFotos(newArray);
            setcurrentlyViewing(endIndex);
        }
    };
    const handleScroll = (e) => {
        const heightScrolled = (e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop);
        const bottom = heightScrolled - e.target.scrollingElement.clientHeight > -250 && heightScrolled - e.target.scrollingElement.clientHeight < 250;
        if (bottom) {
            map10images();

        }
    };
    return { map10images, handleScroll };
}
